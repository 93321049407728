import WorkIm from '../assets/projects/workImg.jpeg'
import RealEst from '../assets/projects/realestate.jpg'
import DSQuester from '../assets/projects/dsquester.png'
import tenzies from '../assets/projects/tenzies.png'
import Vanlife from '../assets/projects/Vanlife.png'
import memegen from '../assets/projects/memegen.png'
import gh from '../assets/projects/gh.png'

export const data=[
    {
        id:1,
        name:"Drug-Symptom Quester",
        image:DSQuester,
        github:"https://github.com/SanaanKhalid/DS-Quester",
        live:"",
    },
    {
        id:2,
        name:"#Vanlife",
        image:Vanlife,
        github:"https://github.com/SanaanKhalid/vanlife-app-react",
        live:"",
    },
    {
        id:3,
        name:"Tenzies",
        image:tenzies,
        github:"https://github.com/SanaanKhalid/tenzies-react",
        live:"",
    },
    {
        id:4,
        name:"Meme Generator",
        image:memegen,
        github:"https://github.com/SanaanKhalid/meme-generator-react",
        live:"",
    },



]